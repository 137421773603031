import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'

const PomnikWLadzyniuPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Kopiec i Pomnik w Sulejówku" />
      <ReturnToPrevPage to={'/gminy/sulejowek'}>
        Materiały dotyczące gminy Sulejówek
      </ReturnToPrevPage>
      <Header1>Kopiec i Pomnik w Sulejówku</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Nieopodal willi „Milusin”, u zbiegu ulic Piłsudskiego i Paderewskiego
          w Sulejówku znajduje się Skwer Niepodległości. Początkowo, czyli od
          1988 roku znajdował się tam powstały z inicjatywy Towarzystwa
          Przyjaciół Sulejówka Kopiec Współtwórców Niepodległej ze stojącym nań
          głazem opatrzonym inskrypcją: „Józef Piłsudski i współtwórcy
          niepodległości: Jędrzej Moraczewski, Ignacy Paderewski, Stanisław
          Grabski, Maciej Rataj Umiłowali Sulejówek. Wdzięczni mieszkańcy
          1918-1988”. Uchwałą Rady Miejskiej z dnia 7 listopada 2008 roku
          zadecydowano o postawieniu na skwerze pomnika. Jego autorem jest
          krakowski rzeźbiarz Karol Badyna.
        </p>
        <p>
          Pomnik przedstawia Józefa Piłsudskiego z córkami. Marszałek w galowym
          mundurze zajmuje fotel, wspierając się na szabli, jego młodsza córka
          Jadwiga siedzi na ławce, trzymając model samolotu, zaś starsza córka
          Wanda stoi obok trzymając lalkę. Autor zatytułował pomnik "Lekcja
          historii". Na przedniej krawędzi cokołu znajduje się napis: „Marszałek
          Józef Piłsudski z córkami Wandą i Jadwigą”. Pomnik uroczyście
          odsłonięto 13 czerwca 2010 roku.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/sulejowek/pomnik-pilsudskiego/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: { relativePath: { regex: "/sulejowek/pomnik-pilsudskiego/" } }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default PomnikWLadzyniuPage
